<script>
export default {
  data () {
    return {
      rules: {
        required: v => !!v || v===0 || '必須項目です',
        email: v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスが正しくありません',
        // tel: v => !v || /^[+]?(?=.*[-])(?=.*[\d])[\d-]*$/.test(v) || '‐（ハイフン）を含めた半角数字で入力してください',
        // zip: v => !v || /^(?=.*[-])(?=.*[\d])[\d-]*$/.test(v) || '‐（ハイフン）を含めた半角数字で入力してください',
        tel: v => !v || /^[0-9]*$/.test(v) || 'ハイフン無しの数字のみで入力してください',
        zip: v => !v || /^[0-9]{7}$/.test(v) || 'ハイフン無しの7桁の数字で入力してください',
        kana: v => /^[ァ-タダ-ヶー]*$/.test(v) || '全角カタカナで入力してください',
        hankaku: (v) => {
          return !v || /^[a-zA-Z0-9-]*$/.test(v) || '半角で入力してください'
        },
        password: v => {
          if (!v) {
            return 'パスワードの設定は必須です'
          }
          if (v.length < 8) {
            return 'パスワードは8文字以上で入力してください'
          }
          if (v.length > 16) {
            return 'パスワードは16文字以内で入力してください'
          }
          return /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9@$#\-_]{8,16}/.test(v) || "パスワードには、英小文字・英大文字・数字それぞれを最低1文字ずつ含む必要があります"
        },
        travelAgencyRegistrationNumber: v => !v || /^[0-9]{1,5}$/.test(v) || '5桁以下の数字で入力してください',
      }
    }
  }
}
</script>
