<template>
  <v-container grid-list-md text-md-center>
    <Loading v-model="loading"></Loading>
    <v-layout row wrap>
      <v-flex xl8 offset-xl2 xs12>
        <div class="Title">
          マイページ
        </div>
      </v-flex>
      <v-flex xl8 offset-xl2 xs12>
        <div class="subTitle">
          <span>登録情報</span>
        </div>
      </v-flex>
      <v-flex xl8 offset-xl2 xs12>
        <v-text-field label="メールアドレス" :value="me.username" readonly class="mx-5"
                      append-icon="mdi-pencil"
                      @click:append="changeEmailModal = true"
        >
          <template v-slot:append>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="changeEmailModal = true">
                  <v-icon v-bind="attrs" v-on="on">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>メールアドレスの変更</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="changePasswordModal = true">
                  <v-icon v-bind="attrs" v-on="on">mdi-account-key</v-icon>
                </v-btn>
              </template>
              <span>パスワードの変更</span>
            </v-tooltip>

            <!--            <v-btn color="primary" text @click="changeEmailModal = true">メールアドレスの変更</v-btn>-->
            <!--            <v-btn color="primary" text>パスワードの変更</v-btn>-->
          </template>
        </v-text-field>
        <!--          </v-col>-->
        <!--        </v-row>-->

      </v-flex>
      <v-flex xl8 offset-xl2 xs12 v-if="paid">
<!--        <h3 class="mt-5">受験番号 {{ me.examineesNumber }}</h3>-->

<!--        <v-img :src="`/api/visitor/me/${event.id}/qr`" width="150" height="150" class="ma-auto"></v-img>-->

        <template v-if="!me.ticketOrder.ticketName.includes('オンライン')">
          <v-btn rounded
                 color="blue-grey"
                 :to="`/api/visitor/${event.id}/ticket`"
                 target="_blank"
                 class="white--text buttonStyle">受講票ダウンロード
          </v-btn>
        </template>
      </v-flex>

<!--      <v-flex xl8 offset-xl2 xs12 v-if="canShowOralTicket-->
<!--      && me.examResult-->
<!--      && me.examResult.canTakeOralExam">-->
<!--        <v-btn rounded-->
<!--               color="blue-grey"-->
<!--               tag="a"-->
<!--               :href="`/api/visitor/${event.id}/oral_ticket`"-->
<!--               target="_blank"-->
<!--               class="white&#45;&#45;text buttonStyle">二次試験受験票ダウンロード-->
<!--        </v-btn>-->
<!--      </v-flex>-->


<!--      <v-flex xl8 offset-xl2 xs12 v-if="canShowWrittenResult && me.examResult">-->
<!--        <div class="subTitle"><span>試験結果情報</span></div>-->

<!--        <h3 class="mt-5">一次試験結果 {{ me.examResult.writtenResult }}</h3>-->
<!--        <table class="ma-auto text-left" v-if="me.examResult.resultDetail">-->
<!--          <tr>-->
<!--            <th>外国語</th>-->
<!--            <td>{{ me.examResult.resultDetail.writtenLanguageResult }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <th>日本地理</th>-->
<!--            <td>{{ me.examResult.resultDetail.writtenGeographyResult }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <th>日本歴史</th>-->
<!--            <td>{{ me.examResult.resultDetail.writtenHistoryResult }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <th>一般常識</th>-->
<!--            <td>{{ me.examResult.resultDetail.writtenCommonSenseResult }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <th>通訳案内の実務</th>-->
<!--            <td>{{ me.examResult.resultDetail.writtenPracticeResult }}</td>-->
<!--          </tr>-->
<!--        </table>-->

<!--        <template v-if="canShowOralResult && me.examResult.oralResult">-->
<!--          <v-btn rounded-->
<!--                 v-if="me.examResult.writtenResult == '合格' && me.examResult.oralResult == '不合格'"-->
<!--                 color="blue-grey"-->
<!--                 :to="`/api/visitor/${event.id}/certificate`"-->
<!--                 target="_blank"-->
<!--                 class="white&#45;&#45;text buttonStyle">一次試験合格証ダウンロード-->
<!--          </v-btn>-->
<!--        </template>-->
<!--      </v-flex>-->

      <v-container>
      </v-container>
      <v-flex xl8 offset-xl2 xs12>
        <div class="subTitle"><span>申請状況</span></div>

        <v-stepper
          v-model="stepper"
          vertical
          flat
        >
          <v-stepper-step
            :complete="entryOk"
            step="1"
            editable
          >
            <span>申込者情報の登録
            <v-icon color="green" v-if="entryOk">mdi-check-circle</v-icon>
            <v-icon color="gray" v-else>mdi-check-circle</v-icon>
            </span>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-btn color="primary" to="/entry" rounded
                   class="white--text buttonStyle">登録画面に進む
            </v-btn>
          </v-stepper-content>

<!--          <v-stepper-step-->
<!--            editable-->
<!--            :complete="subjectOk"-->
<!--            step="2"-->
<!--          >-->
<!--            <span>-->
<!--            その他-->
<!--            <v-icon color="green" v-if="subjectOk">mdi-check-circle</v-icon>-->
<!--            <v-icon color="gray" v-else>mdi-check-circle</v-icon>-->
<!--            </span>-->
<!--          </v-stepper-step>-->

<!--          <v-stepper-content step="2">-->
<!--            <v-btn color="primary" to="/select" rounded-->
<!--                   class="white&#45;&#45;text buttonStyle">登録画面に進む-->
<!--            </v-btn>-->


<!--          </v-stepper-content>-->

          <v-stepper-step
            :complete="!!me.ticketOrder"
            step="2"
          >
            <span>
            申込手数料支払い手続き
            <v-icon color="green" v-if="paymentOk">mdi-check-circle</v-icon>
            <v-icon color="gray" v-else>mdi-check-circle</v-icon>
              </span>
          </v-stepper-step>

          <v-stepper-content step="2">
            <template v-if="entryOpened">
              <v-sheet class="text-caption pa-3 mb-6" outlined>
                【クレジットカード】<br>
                VISA、MASTER、JCB、AMEX、DINERS、DISCOVER が使用できます。
              </v-sheet>


              <v-radio-group v-model="SelectedTicketId" class="pl-3">
                <v-radio
                  v-for="t in tickets"
                  :label=t.name
                  :value="t.id"
                  :disabled="!t.isAvailable"
                ></v-radio>
              </v-radio-group>

              <p v-if="ticket">
                {{ ticket.name }}<span >：{{ ticket.price }} 円(税込)</span><br>
                {{ ticket.description }}
              </p>

              <p class="text-caption text-left">
                下記の個人情報取り扱いについて同意の上、申請いたします。<br>
                【個人情報保護方針について】<a :href="privacyPolicyUrl" target="_blank">{{privacyPolicyUrl}}</a><br>
              </p>
              <!--              <v-btn color="primary" :disabled="!canSubmit" @click.stop="submit" rounded-->
              <!--                     class="white&#45;&#45;text buttonStyle">決済を確定して申請を完了する-->
              <!--              </v-btn>-->
              <SBPaymentForm :ticket="ticket" :disabled="!canSubmit">決済ページへ進む</SBPaymentForm>
            </template>
            <template v-else>
              <p>申請可能期間ではありません</p>
            </template>

          </v-stepper-content>

          <v-stepper-step step="3">
            <span>決済</span>
          </v-stepper-step>
          <v-stepper-content step="3" class="open_force">
            <div v-if="!!me.submittedAt">
              <template
                v-if="me.ticketOrder && me.ticketOrder.paymentMethod == 'SBPAYMENT_CREDIT' && me.ticketOrder.result == 'WaitForPayment'">
                <SBPaymentForm :ticket="ticket" :disabled="!ticket">決済ページへ進む</SBPaymentForm>
              </template>
              <div v-else-if="me.ticketOrder &&  me.ticketOrder.result == 'Success'">
                決済状況：決済済み
                <v-flex xl8 offset-xl2 xs12 v-if="me.ticketPrice > 0">
                  <p class="text-xs-center">
                    <v-btn
                      color="primary"
                      class="white--text buttonStyle"
                      @click="downloadReceipt"
                      rounded
                    >
                      領収書ダウンロード
                    </v-btn>
                  </p>
                </v-flex>
              </div>
            </div>
          </v-stepper-content>
        </v-stepper>


<!--        <div class="subTitle"><span>試験結果</span></div>-->

      </v-flex>

      <AccountEdit v-model="changeEmailModal"></AccountEdit>
      <PasswordEdit v-model="changePasswordModal"></PasswordEdit>

    </v-layout>
  </v-container>
</template>
<script>
import {getReceiptUrl, getTickets} from '../../utils/VisitorAPIUtils'
import axios from 'axios'
import Loading from '../components/Loading'

import {mapGetters} from 'vuex'
import AccountEdit from '../components/AccountEdit'
import PasswordEdit from '../components/PasswordEdit'
import dayjs from 'dayjs'
import SBPaymentForm from "@/event/components/SBPaymentForm";


export default {
  components: {SBPaymentForm, PasswordEdit, Loading, AccountEdit},
  props: {
    event_data: Object,
  },
  data() {
    return {
      loading: true,
      stepper: 1,
      tickets: [],
      callbackToken: null,
      callbackCard: null,
      paymentMethod: "card",
      // paymentOk: false,
      changeEmailModal: false,
      changePasswordModal: false,
      SelectedTicketId: undefined
    }
  },
  computed: {
    ...mapGetters([
      'event', 'eventInfo', 'me', 'accepted', 'entryOpened', 'paid', 'privacyPolicyUrl'
    ]),
    entryOk() {
      return this.me && this.me.entry && !!this.me.entry.submittedAt
    },
    subjectOk() {
      return this.me && this.me.subjectEntry && !!this.me.subjectEntry.submittedAt
    },
    paymentOk() {
      return this.me && !!this.me.ticketId
    },
    canSubmit() {
      let ret = this.entryOk
        // && this.subjectOk
        && !!this.ticket && this.entryOpened
      // if (this.paymentMethod == 'card') {
      //   return ret && !!this.callbackToken
      // }
      return ret
    },
    canReSubmit() {
      let ret = this.entryOk
        // && this.subjectOk
      return ret
    },
    ticket() {
      // let ticketCode = null
      // let isCard
      // switch (this.paymentMethod) {
      //   case 'card':
      //     isCard = true
      //     break
      //   case 'convenience':
      //     isCard = false
      //     break
      //   default:
      //     return null
      // }
      // let hasTwoLanguage = this.me.subjectEntry && !!this.me.subjectEntry.language2 || false
      // if (hasTwoLanguage) {
      //   ticketCode = isCard ? 'TwoLanguageWithCard' : 'TwoLanguageWithConvenienceStore'
      // } else {
      //   ticketCode = isCard ? 'OneLanguageWithCard' : 'OneLanguageWithConvenienceStore'
      // }
      // return this.tickets.find(v => v.code == ticketCode)
      let id = (this.me.ticketOrder && this.me.ticketOrder.ticketId) || this.SelectedTicketId
      return this.tickets.find(v => v.id == id)
    },
    canShowWrittenTicket() {
      let target = this.event.timeline && this.event.timeline.writtenExamTicketOpenAt
      return !!target && dayjs().isAfter(target)
    },
    canShowWrittenResult() {
      let target = this.event.timeline && this.event.timeline.writtenExamResultOpenAt
      return !!target && dayjs().isAfter(target)
    },
    canShowOralTicket() {
      let target = this.event.timeline && this.event.timeline.oralExamTicketOpenAt
      return !!target && dayjs().isAfter(target)
    },
    canShowOralResult() {
      let target = this.event.timeline && this.event.timeline.oralExamResultOpenAt
      return !!target && dayjs().isAfter(target)
    }
  },
  async created() {
    this.loading = true
    await this.$store.dispatch('getMe')
    this.stepper = this.initStepper()

    getTickets({eventId: this.event_data.id}).then(res => {
      this.tickets = res.data
      // this.setTicketStatus();
    }).catch(err => {
      console.log('get tickets catch error. ', err)
    })
    this.loading = false
  },
  methods: {
    initStepper() {
      if (!this.entryOk) return 1
      // if (!this.subjectOk) return 2
      if (!this.me.ticketOrder) return 2
      return 3
    },
    openCardInput() {
      if (window.SpsvApi) {
        window.SpsvApi.spsvCardInfoScreen()
      } else {
        alert('決済ライブラリーの読み込みに失敗しました。時間をあけて再度お試しください')
      }
    },
    async authCard() {
      try {
        await axios.post('/api/status/auth', {
          token: this.callbackToken
        })
        // this.$emit('toast', 'オーソリ成功')
      } catch (e) {
        this.$emit('error', 'こちらのカードはご使用になれません')
      }
    },
    async gatheringCard() {
      try {
        await axios.post('/api/status/gathering', {
          token: this.callbackToken
        })
        this.$emit('toast', '決済が完了しました')
        await this.$store.dispatch('getMe')
        this.stepper = this.initStepper()
        return
      } catch (e) {
        console.error(e, e.response)
        let code = ''
        if (e.response && e.response.data) {
          code = 'エラーコード:' + e.response.data
        }
        this.$emit('error', '決済に失敗しました。' + code)
      }
    },
    async openConveniencePayment() {
      try {
        let {data} = await axios.post('/api/status/add_cnv')
        console.error(data)
        this.$emit('toast', data)
        // return this.getMe()
        window.open(data, 'payment')
        return
      } catch (e) {
        console.error(e, e.response)
        let code = ''
        if (e.response && e.response.data) {
          code = 'エラーコード:' + e.response.data
        }
        this.$emit('error', '決済に失敗しました。' + code)
      }
    },
    async submit() {
      this.loading = true
      try {
        await axios.post('/api/status/submit', {
          // paymentMethod: this.paymentMethod,
          paymentMethod: "demo",
          maskedCardNumber: this.callbackCard,
          token: this.callbackToken
        })
        this.$emit('toast', '提出しました')
        await this.$store.dispatch('getMe')
        this.stepper = this.initStepper()
        return
      } catch (e) {
        console.error(e)
        let code = ''
        if (e.response && e.response.data) {
          code = 'エラーコード:' + e.response.data
          this.$emit('error', '決済に失敗しました。' + code)
        } else {
          this.$emit('error', 'エラーが発生しました。しばらくしてから再度お試しください')
        }
      } finally {
        this.loading = false
      }
    },
    async resubmit() {
      this.loading = true
      try {
        await axios.post('/api/status/resubmit')
        this.$emit('toast', '提出しました')
        await this.$store.dispatch('getMe')
        this.stepper = this.initStepper()
        return
      } catch (e) {
        console.error(e)
        this.$emit('error', 'エラーが発生しました。しばらくしてから再度お試しください')
      } finally {
        this.loading = false
      }
    },
    edit() {

    },
    downloadReceipt() {
      let url = getReceiptUrl({eventId: this.event_data.id})
      window.open(url, '_blank');
    },
  }
}
</script>
<style lang="scss">
.Title {
  font-size: 150%;
}

.subTitle {
  position: relative;
  text-align: left;
  font-size: 130%;
  font-weight: bold;
  color: #959595;
}

.subTitle:before {
  border-top: 1px solid;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}

.subTitle span {
  background-color: white;
  display: inline-block;
  padding: 0 0.5em;
  position: relative;
}

.subContentsTitle {
  font-size: 100%;
}

.buttonStyle {
  width: 50%;
}

.open_force .v-stepper__wrapper {
  height: auto !important;
}

//.v-stepper__wrapper {
//  height: auto !important;
//}
</style>
