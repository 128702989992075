<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="readonly ? null:on"
        :rules="rules"
        :disabled="disabled"
      ></v-text-field>
    </template>
    <v-date-picker
      :type="type"
      v-model="date"
      :active-picker.sync="activePicker"
      :max="max"
      :min="min"
      locale="jp-ja"
      :year-format="yearFormat"
      :pickerDate="pickerDate"
      v-bind="$attrs"
    ></v-date-picker>
  </v-menu>
</template>
<script>
//
// ref="picker"
// locale="jp-ja"
// :day-format="date => new Date(date).getDate()"
// :pickerDate="pickerDate"
export default {
  props: {
    value: {type: String},
    label: {type: String},
    month: {type: Boolean, default: false},
    min: {type: String, default:"1920-01-01"},
    max: {type: String, default:new Date().toISOString().substr(0, 10)},
    baseDate: {type: String, default: new Date().toISOString().substr(0, 10)},
    readonly: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    yearFormat: {type: Function, default: undefined},
    rules: Array
  },
  data () {
    return {
      type: this.month ? 'month' : 'date',
      activePicker: null,
      // date: null,
      menu: false,
      pickerDate: '1995-01-01'
    }
  },
  computed:{
    date:{
      get() {return this.value},
      set(newValue){
        this.$refs.menu.save(newValue)
        this.pickerDate = newValue
        this.$emit('input', newValue)
      }
    }
  },
  watch: {
    // value(v){
    //   this.date = v;
    // },
    menu (val) {
      val && setTimeout(() => {
        this.activePicker = 'YEAR';
        this.pickerDate = null;
      })
    },
  },
  methods: {
    // save (date) {
    //   this.$refs.menu.save(date)
    //   this.pickerDate = date
    //   this.$emit('input', date)
    // }
  }
}
</script>
