<template>
  <div>
    <v-dialog
      v-model="value"
      persistent
      max-width="500px"
    >
      <v-form v-model="valid" lazy-validation @submit.prevent="execute">
        <v-card>
          <v-card-title class="modalHeader">
            <span class="headline">メールアドレスの変更</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md class="pa-6">
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="email"
                    :rules="[rules.required, rules.email]"
                    label="Email Address"
                  >
                  </v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 class="text-md-center">
                  <v-btn class="edit-b" @click.native="close">キャンセル</v-btn>
                  <v-btn type="submit" color="error" :disabled="!valid">確認メールを送信する</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import RulesMixin from '../mixins/RulesMixin'
import axios from 'axios'
import Vue from 'vue'

window.bus = new Vue();
export default {
  mixins: [RulesMixin],
  props: {
    value: Boolean
  },
  data () {
    return {
      valid: false,
      email: ''
    }
  },
  methods: {
    close () {
      this.$emit('input', false)
    },
    async execute () {
      try {
        await axios.post('/api/account/change_email', {
          email: this.email
        })
        bus.$emit('toast', '確認メールを送信しました')
      } catch (e) {
        console.error('failed', e)
        if (e.response.status === 409) {
          return bus.$emit('error', 'そのメールアドレスは既に登録されています')
        }
      }
    }
  }
}
</script>
