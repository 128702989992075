<template>
  <v-container>
    <Loading v-model="loading"></Loading>

    <h2>科目選択</h2>
    <v-form ref="form">
      <v-container>
        <p class="text-caption text-left">
          プルダウンより受験外国語を選択してください。<br>
          ※中国語受験の方は中国語選択後、下部に表示される「簡体字・繁体字」を選択してください。<br>
          ※受験を希望する二カ国語の内、１つ以上の外国語科目が免除となる場合は二カ国語受験が可能です。<br>
          ※免除申請する外国語は右側の「免除申請」にチェックを入れてください。
        </p>
        <v-row>
          <v-col>
            <v-select label="受験外国語"
                      v-model="form.language1"
                      :items="languageOptions1"
                      :disabled="submitted"
                      :rules="[rules.required]"></v-select>
          </v-col>
          <v-col cols="2">
            <v-checkbox label="免除申請"
                        v-model="requestFirstLanguageExemption"
                        :disabled="submitted && !requiredResubmit"
                        v-if="form.language1"
            ></v-checkbox>
          </v-col>
        </v-row>
        <p class="text-caption text-left">
          ※二カ国語受験の方のみ選択してください。<br>
          ※二カ国語受験で一カ国語だけ免除の場合は、免除する方を受験第二外国語に入力してください。
        </p>
        <v-row>
          <v-col>
            <v-select label="受験第二外国語"
                      v-model="form.language2"
                      :disabled="submitted"
                      :items="languageOptions2"></v-select>
          </v-col>
          <v-col cols="2">
            <v-checkbox label="免除申請"
                        v-model="requestSecondLanguageExemption"
                        readonly
                        :disabled="submitted && !requiredResubmit"
                        v-if="form.language2"></v-checkbox>
          </v-col>
        </v-row>

        <v-radio-group v-if="selectChinese" v-model="form.chineseType"
                       :disabled="submitted" row>
          <template v-slot:label> 中国語を受験する方</template>
          <v-radio label="簡体字" value="簡体字"></v-radio>
          <v-radio label="繁体字" value="繁体字"></v-radio>
        </v-radio-group>

        <v-row v-for="s in nonLanguageSubjects" :key="s.id">
          <v-col>
            <v-text-field :value="s.title" readonly outlined dense></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-checkbox label="免除申請" :value="s.id" v-model="requestOtherSubjectExemption"
                        :disabled="submitted && !requiredResubmit"></v-checkbox>
          </v-col>
        </v-row>


        <h3>希望都市</h3>
        <p class="text-caption text-left">
          ※受験外国語により選択できる受験地が異なります。施行要領をご確認の上、選択してください。<br>
          ※一次試験５科目すべてを免除の方は「免除」を選択してください。
        </p>

        <v-select label="一次試験受験地" v-model="form.writtenExamArea" :items="writtenExamAreasOptions"
                  :disabled="submitted && !requiredResubmit"
                  :rules="[rules.required]">
        </v-select>
        <v-select label="二次試験受験地" v-model="form.oralExamArea" :items="oralExamAreasOptions"
                  :disabled="submitted && !requiredResubmit"
                  :rules="[rules.required]"></v-select>

        <template v-if="false">

        <h3>免除科目申請</h3>

        <p class="text-caption text-left">
          ※免除科目申請がある場合は、下記手順で必ず免除科目申請をしてください。<br>
          ※過去に免除申請を行った方も、今年度に免除申請を行う場合は必要書類の提出と新規申請が必要となります。<br>
          <br>
          【免除科目申請手順】<br>
          １．免除科目申請をする「申請の種類」の左側「申請」チェックボックスにチェックを入れてください。<br>
          ２．必要な添付書類、入力項目のご案内が表示されます。表示された書類のアップロード、項目の入力をしてください。<br>
          ３．ページ下部「確定する」ボタンをクリック<br>
          　※登録内容に不備がない場合は、画面上部に「確定しました」と表示が出て、マイページに移動します。<br>
          　※表示が出ない場合は、必須項目に未入力・未登録の項目があります。<br>
          　　該当箇所に赤字で不備内容が表示されますので、表示を確認の上、全て入力・登録をしてください。<br>
          <br>
          【免除書類のアップロード手順】<br>
          ①申請したい免除項目の「申請」にチェック<br>
          ②クリップマークをクリック<br>
          ③アップロードしたいファイルを選択しアップロード<br>
          ④クリップマークの上にアップロードしたファイル名が表示される<br>
          　（ファイルのアップロード完了です）<br>
          ⑤ファイルを複数アップロードする場合は②～④の操作を繰返す<br>
          ※１ファイル20MBまで。
        </p>

        <v-data-table class="text-left"
                      :headers="tableHeader"
                      :items="filteredExemptions"
                      :expanded="expandedItems"
                      :items-per-page="-1"
                      item-key="id"
                      hide-default-footer>
          <template v-slot:item.id="{item}">
            <v-checkbox v-model="form.exemptions" :value="item.id"
                        :disabled="submitted && !requiredResubmit"
            ></v-checkbox>
          </template>
          <template v-slot:expanded-item="{headers, item}">
            <td :colspan="headers.length" class="pa-5">

              <p class="text-caption text-left text-pre">{{ item.documents }}</p>

              <template v-if="form.exemptions.includes(item.id) && !!item.schema">
                <v-jsf :schema="item.schema"
                       :options="vjsfOptions"
                       v-model="form.exemptionData[item.id]">
                  <template v-slot:観光庁研修終了証明書="{label}">
                    <span v-for="item in getExemptionFiles(item.id)" :key="item.fileId" class="mr-1">
                      <v-chip :href="item.url"
                              :close="!submitted || requiredResubmit"
                              @click:close="removeFromExemptionFiles(item)">
                        {{ item.key|fileName }}
                        </v-chip>
                    </span>
                    <v-file-input show-size
                                  :label="label"
                                  required
                                  multiple
                                  :disabled="submitted && !requiredResubmit"
                                  :rules="[rules.files(item.id)]"
                                  @change="upload($event, item.id)"></v-file-input>
                  </template>
                </v-jsf>
              </template>

              <template v-else-if="form.exemptions.includes(item.id)">
                <span v-for="item in getExemptionFiles(item.id)" :key="item.fileId" class="mr-1">
                  <v-chip :href="item.url"
                          :close="!submitted || requiredResubmit"
                          @click:close="removeFromExemptionFiles(item)">
                    {{ item.key|fileName }}
                    </v-chip>
                </span>
                <v-file-input show-size
                              label="ファイルアップロード"
                              required
                              multiple
                              :disabled="submitted && !requiredResubmit"
                              :rules="[rules.files(item.id)]"
                              @change="upload($event, item.id)"></v-file-input>
              </template>
            </td>
          </template>
        </v-data-table>

        </template>

        <v-btn to="/mypage" class="mx-2">マイページに戻る</v-btn>
        <v-btn @click.stop="save" class="mx-2" v-if="!submitted">保存する</v-btn>
        <v-btn color="primary" @click.stop="submit" class="mx-2" v-if="!submitted || requiredResubmit">確定する</v-btn>

      </v-container>
    </v-form>
  </v-container>
</template>
<script>
import axios from 'axios'
import Loading from '../components/Loading'
import VJsf from '@koumoul/vjsf/lib/VJsf.js'
import Rules from '../mixins/RulesMixin'
import SubjectSelection from '../mixins/SubjectSelection'

export default {
  components: {Loading, VJsf},
  mixins: [Rules, SubjectSelection],
  data () {
    return {
      loading: true,
      form: {
        language1: '',
        language2: '',
        chineseType: '',
        requestedSubjects: [],
        exemptions: [],
        exemptionFiles: [],
        exemptionData: {},
        writtenExamArea: '',
        oralExamArea: '',
        data: {}
      },
      schemaData: [],
      expanded: [],
      tableHeader: [
        {text: '申請', value: 'id'},
        {text: '申請の種類', value: 'title'},
        {text: '科目', value: 'subjectName'},
        {text: '免除対象者', value: 'target'},
        // {text: '提出書類', value: 'documents'},
        {text: '免除申請有効期限有無', value: 'hasExpired'},
        {text: '免除申請有効期限', value: 'expiredAt'}
      ],
    }
  },
  async created () {
    await this.fetchSubjects()
    await this.fetchSaved()
    this.loading = false
  },
  computed: {
    submitted () {
      return this.$store.getters.submitted
    },
    requiredResubmit () {
      return this.$store.getters.requiredResubmit
    },
  },
  watch: {},
  methods: {
    async fetchSaved () {
      let {data} = await axios.get('/api/subject/draft')
      if (data) {
        this.form = Object.assign(this.form, data)
        this.schemaData = this.form.exemptionData
        if (data.language1 && data.requestedSubjects.includes(data.language1)) {
          this.requestFirstLanguageExemption = true
        }
        this.requestOtherSubjectExemption = this.nonLanguageSubjects
          .filter(v => data.requestedSubjects.includes(v.id))
          .map(v => v.id)
      }
    },
    async upload (file, exemptionId) {
      if (!file) {
        this.form.exemptionFiles = this.form.exemptionFiles.filter(v => v.id != exemptionId)
        return
      }
      if (Array.isArray(file)) {
        this.loading = true
        try {
          for (let i in file) {
            await this.upload(file[i], exemptionId)
          }
        } catch (e) {
          console.error(e)
        }
        this.loading = false
        return
      }
      if (file.size > 20971520) {
        alert('ファイルサイズは20MB以下でアップロードしてください')
        return
      }
      let params = new FormData()
      params.append('file', file)
      let {data} = await axios.post('/api/visitor/file/upload', params)
      this.form.exemptionFiles.push({
        id: exemptionId,
        fileId: data.id,
        key: file.name
      })
    },
    getExemptionFile (id) {
      return this.form.exemptionFiles.find(v => v.id == id)
    },
    getExemptionFiles (id) {
      return this.form.exemptionFiles.filter(v => v.id == id)
    },
    getExemptionFileUrl (id) {
      let f = this.getExemptionFile(id)
      return f && f.url
    },
    removeFromExemptionFiles (item) {
      this.form.exemptionFiles = this.form.exemptionFiles.filter(v => v.fileId != item.fileId)
    },
    async save () {
      try {
        this.form.requestedSubjects = this.requestedSubjects
        await axios.post('/api/subject/save', this.form)
        this.$emit('toast', '保存しました')
      } catch (e) {
        console.error(e)
        this.$emit('error', 'エラーが発生しました。しばらくしてから再度お試しください')
      }
    },
    async submit () {
      try {
        if (!this.$refs.form.validate()) {
          return
        }
        this.form.requestedSubjects = this.requestedSubjects
        await axios.post('/api/subject/submit', this.form)
        this.$emit('toast', '確定しました')
        await this.$router.push('/mypage')
      } catch (e) {
        console.error(e)
        this.$emit('error', 'エラーが発生しました。しばらくしてから再度お試しください')
      }
    }
  }
}
</script>
<style scoped>
v-simple-table {
  text-align: left;
}
</style>
