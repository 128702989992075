<script>
import axios from 'axios'

export default {
  methods: {
    async findZipCode (v) {
      try {
        let {data} = await axios.get('/api/zipcode/' + v)
        console.log(data)
        return data
      } catch (e) {
        return null
      }
    },
  }
}
</script>
