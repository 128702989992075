<template>
  <div>
    <Loading v-model="loading"></Loading>
    <v-sheet
      class="mb-9 pa-7"
      color="white" v-show="tokenVerified">
      <div class="Title">
        パスワード設定
      </div>
      ご登録いただいたメールアドレスの確認が取れました。<br>
      次回以降、ログインするための新しいパスワードを設定してください。

      <v-text-field
        v-model="password"
        type="password"
        label="パスワード"
        outlined
        required
        :rules="[rules.password]"
        ref="password"
      ></v-text-field>
      <p class="text-caption text-left">
        ※半角英数字「a~z、A～Z、0～9」で入力してください。<br>
        ※パスワードには、英小文字・英大文字・数字それぞれを最低1文字ずつ含む必要があります。<br>
        ※パスワードの長さは、半角8文字以上から半角16文字以下です。<br>
        ※パスワードは必ず控えを取るなどして忘れないようにしてください。
      </p>
      <v-form v-model="valid">
      <v-btn
        color="primary"
        @click.stop="setPassword"
        :disabled="!valid"
      >パスワードを設定する
      </v-btn>
      </v-form>
    </v-sheet>
    <p v-if="tokenInValid">
      メールアドレスの確認ができませんでした。
    </p>
    <Toast></Toast>
  </div>
</template>
<script>
import axios from 'axios'
import Rules from '../mixins/RulesMixin'
import Loading from '../components/Loading'

export default {
  components: {Loading},
  mixins: [Rules],
  data () {
    return {
      loading : false,
      valid: false,
      token: '',
      password: '',
      tokenVerified: false,
      tokenInValid: false
    }
  },
  async created () {
    this.loading = true
    await this.confirmToken()
    this.loading = false
  },
  methods: {
    async confirmToken () {
      try {
        this.token = this.$route.query.token
        await axios.post('/api/account/confirm/' + this.token)
        this.tokenVerified = true
      } catch (e) {
        console.error(e)
        this.tokenInValid = true
      }
    },
    async setPassword () {
      try {
        if (!this.$refs.password.validate()) {
          return;
        }
        this.loading = true
        await axios.post('/api/account/create', {
          token: this.token,
          password: this.password
        });
        await this.$store.dispatch("getMe");
        return this.$router.push('/mypage')
      }catch (e) {
        console.error(e)
      }finally {
        this.loading = false
      }
    }
  }
}
</script>
