<template>
    <v-app id="inspire" style="background-color: white">
        <v-container fluid>
            <v-flex xl4 md4 offset-md4 xs12>
                <div>
                    パスワードを再発行いたします。<br/>
                    登録されているメールアドレスを入力してください。
                </div>
                <div class="mailResetArea">
                    <v-form v-model="valid">
                        <v-text-field
                        v-model="mailAddress"
                        :rules="mailAddressRules"
                        label="メールアドレス"
                        required
                        ></v-text-field>
                    </v-form>
                    <v-btn depressed large color="primary" :disabled="!valid" @click="submit">送信</v-btn>
                </div>
<!--                <div>-->
<!--                  <span><router-link :to="{path: '.'}">イベントページに戻る</router-link></span>-->
<!--                </div>-->
            </v-flex>
        </v-container>
    </v-app>
</template>

<script>
import { sendPasswordResetToken } from '../utils/APIUtils.js'
export default {
  name: 'EventPasswordReset',
  data () {
    return {
      valid:false,
      accountType: 'visitor',
      mailAddress: '',
      publishId: '',
      mailAddressRules: [
        v => !!v || 'メールアドレスは必須です',
        v => (v && v.length <= 128)|| '128文字以下にしてください',
        v => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式で入力を行ってください。'
      ],
    }
  },
  methods: {
    submit () {
      if (confirm('パスワードの再発行を行ってもよろしいですか？')) {
        let data = {username: this.mailAddress, accountType: this.accountType, publishId: this.publishId}
        sendPasswordResetToken({data: data})
          .then(res => {
            alert("ご登録いただいたメールアドレス宛にマイページログインパスワード再設定のご案内を送付いたしました。メールをご確認の上、パスワードの再設定を行ってください。")
            this.$router.push('/')
          })
          .catch(err => {
            alert("入力されたメールアドレスが正しくありません。")
          })
      }
    }
  },
  mounted() {
    this.publishId = this.$route.params.publishId
  }
}
</script>

<style>
    .logo-area{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .logo-area img{
        width: 350px;
    }
    .mailResetArea{
        margin-top: 50px;
        margin-bottom: 30px;
    }
</style>
