<template>
    <v-app id="inspire" style="background-color: white">
        <v-container fluid>
            <v-flex  xl4 md4 offset-md4 xs12>
                <div>
                    新規パスワードを設定してください。
                </div>
                <div class="passwordSettingArea">
                    <v-form v-model="valid">
                        <v-flex md12>
                              <v-text-field v-model="password" :rules="passwordRules" label="パスワード" required type="password"></v-text-field>
                        </v-flex>
                        <v-flex md12>
                              <v-text-field v-model="passwordConfirm" :rules="passwordConfirmRules" label="パスワード（確認）" required type="password"></v-text-field>
                        </v-flex>
                    </v-form>
                    <v-btn depressed large color="primary" :disabled="!valid" @click="submit">送信</v-btn>
                </div>
            </v-flex>
        </v-container>
    </v-app>
</template>

<script>
import { isValidToken, resetPassword } from '../utils/APIUtils.js'
export default {
  name: 'Signin',
    data () {
        return {
            valid:false,
            accountType: '',
            token: '',
            publishId: '',
            password: '',
            passwordRules: [
                v => !!v || 'パスワードは必須です',
                v => (v && v.length <= 55 && v.length >= 8 && /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,55}$/i.test(v))|| 'パスワードは半角英字・数字をどちらも使用した文字列で、8-55文字以内で設定してください'
            ],
            passwordConfirm: '',
            passwordConfirmRules: [
                v => !!v || 'パスワードは必須です',
                v => (v && v.length <= 55 && v.length >= 8 && /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,55}$/i.test(v))|| 'パスワードは半角英字・数字をどちらも使用した文字列で、8-55文字以内で設定してください',
                v => v == this.password || '入力したパスワードが一致しません。'
            ]
        }
    },
    methods:{
        submit () {
            if(confirm('新しいパスワードの設定を行ってもよろしいですか？')){
                let data = {token: this.token, password: this.password, accountType: this.accountType, publishId: this.publishId}
                resetPassword({data: data}).then(res => {
                    alert('パスワードの設定が完了しました。')
                  this.$router.push('/')
                }).catch(err => {
                    console.log("failed reset password", err)
                })
            }
        }
    },
    mounted() {
        this.accountType = this.$route.query.accountType
        this.token = this.$route.query.token

        isValidToken({data: {
            accountType: this.accountType,
            token: this.token
        }}).then(res => {
            if(res.data.publishId != null) {
              this.publishId = res.data.publishId
            }
        }).catch(err => {
            // トークンの期限切れ
            alert('パスワード変更手続きの期限が過ぎています。再度、パスワード変更手続きを開始してください。')
            if (this.accountType === "event") {
                this.$router.push('./' + this.publishId)
            } else {
                this.$router.push('./')
            }
        })

    }
}
</script>

<style>
    .logo-area{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .logo-area img{
        width: 350px;
    }
    .mailResetArea{
        margin-top: 50px;
        margin-bottom: 30px;
    }
</style>
