<template>
  <v-container grid-list-md text-md-center>
    <v-layout row wrap>
      <v-flex xl8 offset-xl2 xs12>

        <h1 class="event-title">{{ event.eventName }}</h1>

        <p class="info-text" v-html="eventInfo.introductionText">
        </p>

        <div class="subTitle">
          <span>電子申請用メールアドレス登録</span>
        </div>
        <p class="info-text" v-html="eventInfo.emailRegistrationNotice">
        </p>

        <template v-if="entryOpened || isPre">
          <v-form ref="form">
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="email"
                  type="email"
                  label="メールアドレス入力"
                  outlined
                  :rules="[rules.required, rules.email]"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <div class="subTitle subContentsTitle" style="text-align:center;"><span>個人情報の取り扱いについて</span></div>
              </v-flex>
              <v-spacer style="margin-top:40px;"></v-spacer>
              <v-flex xs12>
                <a :href="privacyPolicyUrl" class="linkDecoration" target="_blank">こちら</a>
                をご確認いただき、同意される場合はチェックを入れてください。
              </v-flex>
              <!--              <v-flex md5 xs4>-->
              <!--              </v-flex>-->
              <!--              <v-flex xs12 class="text-xs-center" style="justify-content: flex-end" align="center">-->
              <v-flex xs12 style="justify-content:center; align-content: center;">
                <v-checkbox
                  class="d-inline-block"
                  v-model="personalInfoCheck"
                  label="同意する"
                  color="info"
                ></v-checkbox>
              </v-flex>
              <!--              <v-flex md3 xs2>-->
              <!--              </v-flex>-->
              <v-flex xs12 class="text-xs-center">
                <v-btn
                  color="primary"
                  @click.stop="sendInvitation"
                  class="pa-7"
                  :disabled="!personalInfoCheck"
                >上記の個人情報の取り扱いに同意の上<br>
                  電子申請用メールアドレスを登録する
                </v-btn>
              </v-flex>
            </v-layout>
          </v-form>
          <div class="ma-12">
            <v-btn
              color="primary" x-large
              @click.stop="$emit('openLogin')"
            >マイページログイン
            </v-btn>
          </div>
        </template>
        <template v-else>
          <p></p>
        </template>

        <div class="subTitle">
          <span>お知らせ</span>
        </div>
        <p class="info-text" v-html="eventInfo.notificationsText">
        </p>

        <div class="subTitle">
          <span>注意事項</span>
        </div>
        <p class="info-text" v-html="eventInfo.entryNotice">
        </p>


        <div class="subTitle">
          <span>お問い合わせ先</span>
        </div>
        <p class="text-left pa-5">
          研修の申込み等に関するお問い合わせは、以下までお願いいたします。<br />
          旅行業務取扱管理者定期研修事務局<br />
          ０５７０-００６-０５５（通話料がかかります）受付時間 平日09：00〜17：30
        </p>

      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import axios from 'axios'
import Rules from '../mixins/RulesMixin'
import {mapGetters} from 'vuex'

export default {
  mixins: [Rules],
  data() {
    return {
      email: '',
      personalInfoCheck: false
    }
  },
  computed: {
    ...mapGetters([
      'event', 'eventInfo', 'entryOpened', 'isPre', 'privacyPolicyUrl'
    ])
  },
  methods: {
    async sendInvitation() {
      if (!this.$refs.form.validate()) {
        return
      }
      try {
        let response = await axios.post('/api/account/register', {
          email: this.email
        })
        this.$emit('toast', 'メールを送信しました')
      } catch (e) {
        console.error('failed', e)
        if (e.response.status === 409) {
          return this.$emit('error', 'そのメールアドレスは既に登録されています')
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.event-title {
  margin: 20px 0 40px 0;
  color: #959595;
  font-size: 48px;
}
</style>
