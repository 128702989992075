import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes: {
      light: {
        primary: "#6c9cce",
        secondary: "#8cc9e8",
        // accent: '#8c9eff',
        // error: '#b71c1c',
        info: '#6c9cce',
        // success: '#4CAF50',
        // warning: '#FFC107',
      },
    },
  },
})
