// For Visitor
import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

import MyPage from '@/event/views/MyPage'
import Top from './views/Top'
import AccountConfirm from './views/AccountConfirm'
import EntrySheet from './views/EntrySheet'
import SelectSubject from './views/SelectSubject'
import EventPasswordReset from '../components/EventPasswordReset'
import PasswordSetting from '../components/PasswordSetting'
import store from './store'
import ChangeEmailConfirm from './views/ChangeEmailConfirm'

Vue.use(VueRouter)
Vue.use(Meta)

const router = new VueRouter({
  base: '/',
  mode: 'history',
  relative: true,
  props: {
    loggedIn: Boolean
  },
  routes: [
    {
      path: '/',
      component: Top
    },
    {
      path: '/confirm',
      component: AccountConfirm
    },
    {
      path: '/mypage',
      component: MyPage,
      meta: {requiresAuth: true},
      props: true
    },
    {
      path: '/entry',
      component: EntrySheet,
      meta: {requiresAuth: true},
    },
    // {
    //   path: '/select',
    //   component: SelectSubject,
    //   meta: {requiresAuth: true},
    // },
    // {
    //   path: '/:publishId/MyPage',
    //   name: 'MyPage',
    //   component: MyPage
    // },
    {
      path: '/passwordReset',
      component: EventPasswordReset
    },
    {
      path: '/PasswordSetting',
      component: PasswordSetting
    },
    {
      path: '/change_email',
      component: ChangeEmailConfirm
    },
    // {
    //   path: '/VisitorInvitation',
    //   name: 'VisitorInvitation',
    //   component: VisitorInvitation
    // },
    // {
    //   path: '/Error',
    //   name: 'Error',
    //   component: Error
    // },
    // {
    //   path: '/:publishId',
    //   name: 'Publish',
    //   component: Publish
    // },
    // {
    //   path: '/:publishId/payment/square/:uuid',
    //   name: 'PaymentSquare',
    //   component: PaymentSquare
    // }
  ],
  scrollBehavior (to, from, savedPosition) {
    return {x: 0, y: 0} // トップに移動
  }
})

router.beforeEach((to, from, next) => {
  console.log(to, from, store.state.loggedIn)
  if (to.matched.some(record => record.meta.requiresAuth) && !store.state.loggedIn) {
    next("/")
  }else{
    next()
  }

  // if (to.matched.some(record => record.meta.requiresAuth) && !store.state.loggedIn) {
  //   next({
  //     path: '/',
  //     // query: {redirect: to.fullPath}
  //   })
  // } else {
  //   next()
  // }
  // // next(vm => {
  // //   if(!vm.loggedIn){
  // //     next("/")
  // //   }
  // // })
})

export default router
