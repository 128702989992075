
// オブジェクト定義[OrderDetail]
function OrderDetail() {
  this.toString = function () {
    var result =
      this.dtl_rowno +
      this.dtl_item_id +
      this.dtl_item_name +
      this.dtl_item_count +
      this.dtl_tax +
      this.dtl_amount +
      this.dtl_free1 +
      this.dtl_free2 +
      this.dtl_free3;
    return result;
  }
}


let hashkey = "c48e0e2c7d04f0954594f14c7801bd430ca6263e"
// オブジェクト定義[Order]
function Order() {
  this.orderDetail = new Array();
  this.toString = function () {

    var resultOrderDetail = "";
    for (var i = 0; i < this.orderDetail.length; i++) {
      resultOrderDetail = resultOrderDetail + this.orderDetail[i].toString();
    }

    var result =
      this.pay_method +
      this.merchant_id +
      this.service_id +
      this.cust_code +
      this.sps_cust_no +
      this.sps_payment_no +
      this.order_id +
      this.item_id +
      this.pay_item_id +
      this.item_name +
      this.tax +
      this.amount +
      this.pay_type +
      this.auto_charge_type +
      this.service_type +
      this.div_settele +
      this.last_charge_month +
      this.camp_type +
      this.tracking_id +
      this.terminal_type +
      this.success_url +
      this.cancel_url +
      this.error_url +
      this.pagecon_url +
      this.free1 +
      this.free2 +
      this.free3 +
      this.free_csv +
      resultOrderDetail +
      this.request_date +
      this.limit_second +
      hashkey;
    return result;
  };
}

export {
  Order, OrderDetail
}
