<script>
import axios from 'axios'

export default {
  data () {
    return {
      subjects: [],
      venues: [],
      exemptions: [],
      writtenExamAreas: [],
      oralExamAreas: [],
      requestFirstLanguageExemption: false,
      requestSecondLanguageExemption: true,
      requestOtherSubjectExemption: [],
      rules: {
        files: (id) => {
          return (v) => !!v || this.getExemptionFiles(id).length > 0 || '必須項目です'
        }
      }
    }
  },
  filters: {
    fileName (v) {
      return v && v.substring(v.lastIndexOf('/') + 1)
    }
  },
  computed: {
    languages () {
      return this.subjects.filter(s => s.isLanguage)
    },
    nonLanguageSubjects () {
      return this.subjects.filter(s => !s.isLanguage)
    },
    languageOptions1 () {
      return this.languages.map(v => {
        return {
          text: v.title,
          value: v.id,
          disabled: v.id == this.form.language2
        }
      })
    },
    languageOptions2 () {
      let ret = this.languages.map(v => {
        return {
          text: v.title,
          value: v.id,
          disabled: v.id == this.form.language1
        }
      })
      ret.unshift({
        text: '-',
        value: null
      })
      return ret
    },
    language1 () {
      return this.languages.find(v => v.id == this.form.language1)
    },
    language2 () {
      return this.languages.find(v => v.id == this.form.language2)
    },
    selectChinese () {
      return this.languages.filter(v => v.id == this.form.language1 || v.id == this.form.language2)
        .some(v => v.title == '中国語')
    },
    requestedSubjects () {
      let ret = []
      if (this.requestFirstLanguageExemption && this.form.language1) {
        ret.push(this.form.language1)
      }
      if (this.requestSecondLanguageExemption && this.form.language2) {
        ret.push(this.form.language2)
      }
      return ret.concat(this.requestOtherSubjectExemption)
    },
    canAvoidWrittenExam () {
      return [this.language1, this.nonLanguageSubjects].flat().every(v => {
        return (this.requestedSubjects || []).some(id => id == v.id)
      })
    },
    writtenExamAreasOptions () {
      let lang1 = this.languages.find(v => v.id == this.form.language1)
      let lang2 = this.languages.find(v => v.id == this.form.language2)
      if (!lang1) return []
      let ret
      if (!lang2) {
        ret = lang1.writtenExamAreas
      } else {
        ret = lang1.writtenExamAreas.filter(a => lang2.writtenExamAreas.some(b => a.id == b.id))
      }
      ret = ret
        .map(v => {
          return {
            text: v.title,
            value: v.id
          }
        })
      if (this.canAvoidWrittenExam) {
        ret.push({
          text: '免除',
          value: 0
        })
      }
      return ret
    },
    oralExamAreasOptions () {
      let lang1 = this.languages.find(v => v.id == this.form.language1)
      let lang2 = this.languages.find(v => v.id == this.form.language2)
      if (!lang1) return []
      let ret
      if (!lang2) {
        ret = lang1.oralExamAreas
      } else {
        ret = lang1.oralExamAreas.filter(a => lang2.oralExamAreas.some(b => a.id == b.id))
      }
      let result = ret.map(v => {
        return {
          text: v.title,
          value: v.id
        }
      })
      if (['東京近郊', '大阪近郊'].includes(this.writtenExamArea.text)
        && result.some(v => v.text == this.writtenExamArea.text)) {
        if (this.form.oralExamArea != this.writtenExamArea.value) {
          this.form.oralExamArea = null
        }
        return [this.writtenExamArea]
      }
      return result
    },
    writtenExamArea () {
      return this.writtenExamAreasOptions.find(v => v.value == this.form.writtenExamArea) || {}
    },
    requestedSubjectItems () {
      return (this.requestedSubjects || []).map(id => {
        return this.subjects.find(v => v.id == id)
      }).filter(v => !!v)
    },
    filteredExemptions () {
      let items = this.requestedSubjectItems.map(v => v.title)
      return this.exemptions.filter(v => {
        if (/\-/.test(v.subjectName)) return true
        if (/該当/.test(v.subjectName)) return true
        for (const k in items) {
          let t = items[k]
          t = t == 'フランス語' ? '仏語' : t
          t = t == 'スペイン語' ? '西語' : t
          t = t == 'ドイツ語' ? '独語' : t
          t = t == 'イタリア語' ? '伊語' : t
          const regex = new RegExp(t)
          if (regex.test(v.subjectName)) return true
        }
        return false
      })
    },
    expandedItems () {
      return this.exemptions.filter(v => this.form.exemptions.includes(v.id))
    },
    vjsfOptions(){
      return {
        disableAll: this.submitted && !this.requiredResubmit,
        messages: {
          required: '必須項目です',
          minLength: '{minLength}文字未満です',
          maxLength: '{maxLength}文字以上です'
        }
      }
    },
  },
  methods: {
    async fetchSubjects () {
      let {data} = await axios.get('/api/subject')
      this.subjects = data.subjects
      this.exemptions = data.exemptions
      this.writtenExamAreas = data.writtenExamAreas
      this.oralExamAreas = data.oralExamAreas
    },
  }
}
</script>
