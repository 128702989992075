<template>
  <v-app light>
    <v-app-bar dense app
               elevation="0"
               color="white"
      id="fix-header"
    >
      <v-app-bar-title id="fix-header-event-title">{{ event.eventName }}</v-app-bar-title>
      <v-spacer></v-spacer>
      <span v-if="!loginOpened && !isPre"></span>
      <v-menu offset-y v-else-if="loggedIn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined color="primary" v-bind="attrs" v-on="on">メニュー</v-btn>
        </template>
        <v-list>
          <v-list-item to="/mypage">
            <v-list-item-title>マイページ</v-list-item-title>
          </v-list-item>
          <v-list-item to="/">
            <v-list-item-title>トップページ</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logOut" selectable>
            <v-list-item-title>ログアウト</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-dialog
        v-model="loginDialog"
        persistent
        max-width="500px"
        v-else
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined color="primary" v-bind="attrs" v-on="on" >ログイン</v-btn>
        </template>
        <v-form v-model="validLogin" lazy-validation @submit.prevent="singIn()">
          <v-card>
            <v-card-title class="modalHeader">
              <span class="headline">マイページにログイン</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md class="pa-6">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-text-field
                      v-model="loginInfo.email"
                      :rules="[rules.required, rules.email]"
                      label="Email Address"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <!--                      :rules="[rules.required, rules.password]"-->
                    <v-text-field
                      v-model="loginInfo.password"
                      :append-icon="loginInfo.showPassword ? 'visibility_off' : 'visibility'"
                      label="Password"
                      :type="loginInfo.showPassword ? 'text' : 'password'"
                      @click:append="loginInfo.showPassword = !loginInfo.showPassword"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12 class="text-md-center">
                    <v-btn class="edit-b" @click.native="loginDialog = false">キャンセル</v-btn>
                    <v-btn type="submit" color="error" :disabled="!validLogin">ログイン</v-btn>
                  </v-flex>
                  <v-flex xs12 class="text-md-center">
                    <v-icon style="position: relative; top:5px;">help</v-icon>
                    <!--                    <span><router-link :to="{path: '/passwordReset'}">パスワードを忘れた方はこちら</router-link></span>-->
                    <span><a @click="gotoPasswordReset">パスワードを忘れた方はこちら</a></span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-form>
      </v-dialog>
    </v-app-bar>
    <v-main>
      <router-view
        @toast="toast"
        @error="error"
        :eventId="event.eventId"
        :event_data="event"
        @openLogin="openLogin"
      ></router-view>

      <v-footer
        color="primary"
        padless
        class="mt-16"
      >
        <v-row
          justify="center"
          no-gutters
        >
          <v-btn color="white" text rounded class="my-2" tag="a"
                 :href="privacyPolicyUrl" target="_blank">
            プライバシーポリシー
          </v-btn>
          <v-btn color="white" text rounded class="my-2" tag="a"
                 href="https://cdn.demo.allinone-series.com/doc/%E3%80%8C%E7%89%B9%E5%AE%9A%E5%95%86%E5%8F%96%E5%BC%95%E6%B3%95%E3%81%AB%E5%9F%BA%E3%81%A4%E3%82%99%E3%81%8F%E8%A1%A8%E7%A4%BA%E3%80%8D220505.pdf" target="_blank">
            特定商取引法表示
          </v-btn>
        </v-row>
      </v-footer>
    </v-main>


    <v-snackbar v-model="showToast" top :color="toastColor">
      {{ toastText }}
    </v-snackbar>
  </v-app>
</template>

<script>
import Rules from './mixins/RulesMixin'
import Vue from 'vue'
import {mapGetters} from 'vuex'

window.bus = new Vue()

export default {
  mixins: [Rules],
  data () {
    return {
      loading: false,
      event_data: {},
      items: [
        {title: ' ログアウト '}
      ],
      showToast: false,
      toastText: null,
      toastColor: 'success',
      dialog: false,
      loginDialog: false,
      loginInfo: {
        email: null,
        password: null,
        showPassword: false
      },
      validLogin: false
    }
  },
  async created () {
    bus.$on('toast', this.toast)
    bus.$on('error', this.error)
    let p = this.$store.dispatch('getEvent')
    let p2 = this.$store.dispatch('getMe')
    await Promise.all([p, p2])
  },
  mounted() {
    setTimeout(() => {
      this.setStyleFixHeader()
    }, 1000)
    window.addEventListener("scroll", this.setStyleFixHeader)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setStyleFixHeader)
  },
  computed: {
    ...mapGetters([
      'event', 'eventInfo', 'entryOpened', 'me', 'loggedIn', 'isPre', 'loginOpened', 'privacyPolicyUrl'
    ])
  },
  methods: {
    toPreview (data) {
      this.eventData = data
    },
    async openLogin () {
      await this.$store.dispatch('getMe')
      if (this.loggedIn) {
        return this.$router.push('/mypage')
      }
      this.loginDialog = true
    },
    async singIn () {
      let ret = await this.$store.dispatch('login', {
        username: this.loginInfo.email,
        password: this.loginInfo.password,
        accountType: 'visitor',
        eventId: this.eventId
      })
      if (ret) {
        this.loginDialog = false
        await this.$router.push({path: '/mypage'})
      }
    },
    async logOut () {
      await this.$store.dispatch('logout')
      await this.$router.push({path: '/'}).catch(() => {
      })
    },
    toast (v) {
      this.toastText = v
      this.toastColor = 'success'
      this.showToast = true
    },
    error (v) {
      this.toastText = v
      this.toastColor = 'error'
      this.showToast = true
    },
    gotoPasswordReset () {
      this.loginDialog = false
      return this.$router.push('/passwordReset')
    },
    setStyleFixHeader() {
      let fixHeader = document.getElementById("fix-header")
      let fixHeaderEventTitle = document.getElementById("fix-header-event-title")
      if(window.scrollY > 0) {
        fixHeader.className = "visible scroll"
        fixHeaderEventTitle.className = "visible"
      } else {
        fixHeader.className = "visible"
        fixHeaderEventTitle.className = ""
      }
    },
  },
}
</script>

<style>
* {
  /* macOs catalina + Chromeでsans-serifが明朝体になってしまう事象の対応 */
  font-family: 'Avenir', Helvetica, Arial, YuGothic, sans-serif;
}

.headline {
  /* macOs catalina + Chromeでsans-serifが明朝体になってしまう事象の対応 */
  /* vuetify側でimportant指定されていたためimportant指定して上書く */
  font-family: 'Avenir', Helvetica, Arial, YuGothic, sans-serif !important;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, YuGothic, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #666666;
}

.headerlogo {
  width: 200px;
  margin-top: 5px;
}

@media screen and (max-width: 960px) {
  .headerlogo {
    width: 150px;
    margin-top: 5px;
  }
}
</style>

<style scoped>
#fix-header {
  display: block;
  width: 100%;
  height: 70px;
  border-bottom: none;
  position: fixed;
//top: -70px; left: 0px; z-index: 100; background-color: white;
}

#fix-header.visible {
  top: 0px;
  border-bottom: none;

  transition-property: top;
  transition-duration: 0.5s;
}

#fix-header.scroll {
  border-bottom: solid 1px #cccccc;
}

#fix-header-event-title {
  color: #FFFFFF;
  font-size: 150%;
  font-weight: bold;
  visibility: visible;
  transition-property: color;
  transition-duration: 1s;
}

#fix-header-event-title.visible {
  color: #959595;
  visibility: visible;
  transition-duration: 1s;
}

.textarea {
  color: #969696;
  margin-top: 60px;
  margin-bottom: 30px;
  font-size: 110%;
}

.organizer {
  color: #969696;
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 110%;
}

.subTitle {
  position: relative;
  text-align: left;
  font-size: 130%;
  font-weight: bold;
  color: #959595;
}

.subTitle:before {
  border-top: 1px solid;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}

.subTitle span {
  background-color: white;
  display: inline-block;
  padding: 0 0.5em;
  position: relative;
}

.subContentsTitle {
  font-size: 110%;
}

.contents {
  font-size: 150%;
  color: #959595;
  font-weight: bold;
  text-indent: 5px;
}

.maparea {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mapresize {
  width: 560px;
  height: 315px;
  display: block;
  overflow: hidden;
  position: relative;
}

.input-area {
  background-color: #F0F0F0;
  padding-top: 20px;
  padding-bottom: 50px;
  color: #959595;
}

.inputFieldTitle {
  text-align: left;
  font-weight: bold;
}

.inputAreaTitle {
  font-size: 150%;
  font-weight: bold;
}

.linkDecoration {
  text-decoration: none;
  color: #5f9ddd;
  font-weight: bold;
}

.modalHeader {
  background-color: #263238;
  color: white;
  height: 70px;
  font-size: 160%;
  font-weight: bold;
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

.essential {
  background-color: #f44336;
  color: white;
  font-size: 13px;
  font-weight: bold;
  padding: 4px 0.5em;
  vertical-align: top;
  margin-left: 1.1em;
  border-radius: 10px; /* CSS3草案 */
  -webkit-border-radius: 10px; /* Safari,Google Chrome用 */
  -moz-border-radius: 10px; /* Firefox用 */
}

.attention {
  margin-left: 10px;
  font-weight: normal;
}

.siteAccessTitle {
  background-color: #263238;
  padding: 15px;
  color: white;
  font-size: 20px;
}

.siteAccessContent {
  margin-bottom: 40px;
  margin-top: 40px;
  font-size: 17px;
}

.ticketRadio {
  background-color: #ffffff;
  padding: 20px;
  margin-right: 0px;
}

.ticketRadio span.ticketLabel {
  color: rgba(0, 0, 0, 0.77);
}

.ticketDescription {
  color: rgba(0, 0, 0, 0.77);
  padding-left: 30px;
  padding-top: 10px;
  font-size: 90%;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>

<style>
.info-text{
  text-align: left;
}
.info-text p{
  margin: 0 !important;
}
</style>
