<template>
  <div>
    <v-dialog
      v-model="value"
      persistent
      max-width="500px"
    >
      <v-form v-model="valid" lazy-validation @submit.prevent="execute">
        <v-card>
          <v-card-title class="modalHeader">
            <span class="headline">パスワードの変更</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md class="pa-6">
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="password"
                    :rules="[rules.required, rules.hankaku, rules.password]"
                    label="パスワード"
                  >
                  </v-text-field>
                  <p class="text-caption text-left">
                    ※パスワードは、半角英数字で英小文字・英大文字・数字それぞれを最低1文字ずつ含む8文字以上16文字以内で入力してください
                  </p>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 class="text-md-center">
                  <v-btn class="edit-b" @click.native="close">キャンセル</v-btn>
                  <v-btn type="submit" color="error" :disabled="!valid">変更する</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import RulesMixin from '../mixins/RulesMixin'
import axios from 'axios'
import Vue from 'vue'

window.bus = new Vue();
export default {
  mixins: [RulesMixin],
  props: {
    value: Boolean
  },
  data () {
    return {
      valid: false,
      password: ''
    }
  },
  methods: {
    close () {
      this.$emit('input', false)
    },
    async execute () {
      try {
        await axios.post('/api/account/change_password', {
          password: this.password
        })
        alert('変更しました')
        this.close()
      } catch (e) {
        console.error('failed', e)
        bus.$emit('error', '変更に失敗しました')
      }
    }
  }
}
</script>
