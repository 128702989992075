<template>
  <v-container>
    <Loading v-model="loading"></Loading>

    <v-form v-model="valid" ref="form">
      <v-container>

        <h2>受講者の登録</h2>

        <v-row>
          <v-col>
            <v-text-field label="姓" v-model="form.lastName" placeholder="姓"
                          :disabled="disableEditName"
                          :rules="[rules.required]"></v-text-field>
          </v-col>
          <!--          <v-col>-->
          <!--            <v-text-field label="ミドルネーム" v-model="form.middleName"-->
          <!--                          :disabled="disableEditName"></v-text-field>-->
          <!--          </v-col>-->
          <v-col>
            <v-text-field label="名" v-model="form.firstName" placeholder="名"
                          :disabled="disableEditName"
                          :rules="[rules.required]"></v-text-field>
          </v-col>
        </v-row>
        <!--        <p class="text-caption text-left mt-5">-->
        <!--          ※氏名が漢字の場合であれば必ず入力してください。<br>-->
        <!--          ※外国籍かつ日本居住者は在留カードに記載されている通りに入力してください。<br>-->
        <!--        </p>-->
        <v-row>
          <v-col>
            <v-text-field label="姓(フリガナ)" v-model="form.lastNameKana"
                          :disabled="disableEditName"
                          :rules="[rules.required, rules.kana]"></v-text-field>
          </v-col>
          <!--          <v-col>-->
          <!--            <v-text-field label="ミドルネーム(フリガナ)" v-model="form.middleNameKana"-->
          <!--                          :disabled="disableEditName"-->
          <!--                          :rules="[rules.kana]"></v-text-field>-->
          <!--          </v-col>-->
          <v-col>
            <v-text-field label="名(フリガナ)" v-model="form.firstNameKana"
                          :disabled="disableEditName"
                          :rules="[rules.required, rules.kana]"></v-text-field>
          </v-col>
        </v-row>


        <DatePicker v-model="form.birthdate"
                    label="生年月日"
                    baseDate="1995-01-01"
                    :rules="[rules.required]"
                    class="mt-12"
        ></DatePicker>

        <h3 class="mt-12">現住所</h3>

        <v-text-field label="郵便番号(必須)※半角数字のみ７ケタ、ハイフン無し" v-model="form.zipcode"
                      validate-on-blur
                      :rules="[rules.hankaku,rules.zip]"
                      @change="checkZipcode($event,true)"
                      :error-messages="zipcodeError"></v-text-field>
        <v-text-field label="都道府県（必須）" v-model="form.prefecture" :rules="[rules.required]"></v-text-field>
        <v-text-field label="市区町村（必須）入力例：千代田区霞が関３丁目" v-model="form.address" :rules="[rules.required]"></v-text-field>
        <v-text-field label="番地（必須）入力例：３番3号" v-model="form.address2" :rules="[rules.required]"></v-text-field>
        <v-text-field label="ビル名・部屋番号" v-model="form.address3"></v-text-field>

        <v-text-field label="社名(必須)" v-model="form.organization" :rules="[rules.required]"></v-text-field>
        <v-text-field label="営業所名" v-model="form.salesOffice"></v-text-field>
        <v-text-field label="部署名" v-model="form.department"></v-text-field>

        <v-select label="登録行政庁（必須）プルダウンで選択してください"
                  v-model="form.registrationAdministrativeAgency"
                  :items="[
                    '観光庁長官',
                    '北海道知事',
                    '青森県知事',
                    '岩手県知事',
                    '宮城県知事',
                    '秋田県知事',
                    '山形県知事',
                    '福島県知事',
                    '茨城県知事',
                    '栃木県知事',
                    '群馬県知事',
                    '埼玉県知事',
                    '千葉県知事',
                    '東京都知事',
                    '神奈川県知事',
                    '新潟県知事',
                    '富山県知事',
                    '石川県知事',
                    '福井県知事',
                    '山梨県知事',
                    '長野県知事',
                    '岐阜県知事',
                    '静岡県知事',
                    '愛知県知事',
                    '三重県知事',
                    '滋賀県知事',
                    '京都府知事',
                    '大阪府知事',
                    '兵庫県知事',
                    '奈良県知事',
                    '和歌山県知事',
                    '鳥取県知事',
                    '島根県知事',
                    '岡山県知事',
                    '広島県知事',
                    '山口県知事',
                    '徳島県知事',
                    '香川県知事',
                    '愛媛県知事',
                    '高知県知事',
                    '福岡県知事',
                    '佐賀県知事',
                    '長崎県知事',
                    '熊本県知事',
                    '大分県知事',
                    '宮崎県知事',
                    '鹿児島県知事',
                    '沖縄県知事']"
                  :rules="[rules.required]"></v-select>

        <v-select label="登録種別（必須）プルダウンで選択してください"
                  v-model="form.registrationType"
                  :items="['第1種','第2種','第3種','地域限定','旅行業者代理業','旅行サービス手配業']"
                  :rules="[rules.required]"></v-select>

        <v-text-field label="旅行業登録番号（必須）" v-model="form.travelAgencyRegistrationNumber"
                      :rules="[rules.required, rules.travelAgencyRegistrationNumber]"></v-text-field>

        <DatePicker v-model="form.travelAgencyRegistrationDate"
                    label="旅行業更新年月日（必須）"
                    :rules="[rules.required]"
                    class="mt-12"
                    min="2022-01-01"
                    max="2029-12-31"
                    :year-format="yearFormat"
        ></DatePicker>
        <p class="text-caption text-left">
        ※旅行業代理業者の更新年月日については、所属旅行業者の更新年月日を入力してください。<br/>
        ※旅行サービス手配業者の更新年月日については、登録年月日を入力してください。
        </p>

        <v-text-field label="電話番号（必須） 半角数字のみ、ハイフンなし。例）0123456789" v-model="form.tel" :rules="[rules.tel, rules.required]"></v-text-field>


        <h3 class="mt-12">テキスト・修了証送付先 ※オンライン受講の方のみご記載下さい。</h3>

        <p class="text-left font-weight-bold pa-4 my-4" style="border: solid 2px #ccc">
          現住所と同じ方は下記にチェックを入れてください。<br/>
          現住所と同じでない方は、下記にご記載ください。<br/>
          記入がない場合には、現住所へ送付させていただきます。
        </p>
        <v-checkbox v-model="form.notifySameAddress" label="現住所と同じ"
        ></v-checkbox>

        <v-text-field label="送付先郵便番号 ※半角数字のみ７ケタ、ハイフン無し"
                      v-model="form.notifyZipcode"
                      validate-on-blur
                      :rules="[rules.zip, rules.notifyZipcode]"
                      @change="checkNotifyZipcode"
                      :error-messages="notifyZipcodeError"
                      :disabled="!!form.notifySameAddress"
        ></v-text-field>
        <v-text-field label="【住所1】都道府県から番地まで（テキスト・修了証送付先）" v-model="form.notifyAddress"
                      placeholder="※必ず都道府県からご入力ください。入力例：東京都千代田区霞が関3丁目３番３号"
                      :disabled="!!form.notifySameAddress"
                      :rules="[]"></v-text-field>
        <v-text-field label="【住所2】ビル名・マンション名等（テキスト・修了証送付先）" v-model="form.notifyAddress2"
                      :disabled="!!form.notifySameAddress"></v-text-field>
        <v-text-field label="社名（テキスト・修了証送付先）" v-model="form.notifyOrganization"
                      placeholder="送付先がご勤務先の場合のみ入力してください。"
                      :disabled="!!form.notifySameAddress"></v-text-field>
        <v-text-field label="勤務先営業所名（テキスト・修了証送付先）" v-model="form.notifyOffice"
                      placeholder="送付先がご勤務先の場合のみ入力してください。"
                      :disabled="!!form.notifySameAddress"></v-text-field>
        <v-text-field label="勤務先部署（テキスト・修了証送付先）" v-model="form.notifyDepartment"
                      placeholder="送付先がご勤務先の場合のみ入力してください。"
                      :disabled="!!form.notifySameAddress"></v-text-field>


        <p class="text-caption text-center">
          ※「確定する」ボタンをクリックても画面上部に「確定しました」と表示が出ない場合は、<br>
          　必須項目に未入力・未登録の項目があります。該当箇所に赤字で不備内容が表示されますので、<br>
          　表示を確認の上、全て入力・登録をしてください。
        </p>
        <div class="text-center">
          <v-btn to="/mypage" class="mx-2">マイページに戻る</v-btn>
          <v-btn @click.stop="save" class="mx-2" v-if="!submitted">一時保存する</v-btn>
          <v-btn color="primary" @click.stop="submit" class="mx-2">確定する</v-btn>
        </div>
      </v-container>
    </v-form>

  </v-container>
</template>
<script>
import axios from 'axios'
import dayjs from 'dayjs'
import DatePicker from '../components/DatePicker'
import {prefectures} from '../../data/prefectures.json'
import countries from '../../data/countries.json'
import Rules from '../mixins/RulesMixin'
import Loading from '../components/Loading'
import ZipCodeChecker from '../mixins/ZipCodeChecker'
import {mapGetters} from 'vuex'

export default {
  components: {DatePicker, Loading},
  mixins: [Rules, ZipCodeChecker],
  data() {
    return {
      loading: true,
      form: {
        lastName: '',
        firstName: '',
        middleName: '',
        lastNameKana: '',
        firstNameKana: '',
        middleNameKana: '',
        gender: '',
        birthdate: '',
        permanentAddress: '',
        nationality: '',
        nationalityOther: '',
        picture: '',
        pictureDate: '',
        pictureUrl: '',
        zipcode: '',
        prefecture: '',
        address: '',
        address2: '',
        address3: '',
        organization: '',
        salesOffice: '',
        department: '',
        registrationAdministrativeAgency: '',
        registrationType: '',
        travelAgencyRegistrationNumber: '',
        travelAgencyRegistrationDate: '',
        tel: '',
        fax: '',
        notifySameAddress: false,
        notifyZipcode: '',
        notifyAddress: '',
        notifyAddress2: '',
        notifyAddressLabel: '',
        notifyOrganization: '',
        notifyOffice: '',
        notifyDepartment: '',
        notifyTel: '',
        notifyFax: '',
        urgentType: '',
        urgentTypeOther: '',
        urgentTel: '',
        urgentFax: '',
        consideration: '',
        documents: []
      },
      rules: {
        photo: (v) => {
          return (!!this.form.pictureUrl) || '必須項目です'
        },
        permanentAddress: (v) => {
          return (!!v || !!this.form.nationality) || '日本国籍かつ日本居住者の場合は必須項目です'
        },
        nationality: (v) => {
          return (!!v || !!this.form.permanentAddress) || '外国籍の場合、または日本国籍かつ外国居住者の場合は必須項目です'
        },
        nationalityOther: (v) => {
          return (!!v || this.form.nationality != 'その他') || '国籍が「その他」の場合必須'
        },
        notifyAddress: (v) => {
          return !!this.form.notifySameAddress || !!v || '必須項目です'
        },
        urgentTypeOther: (v) => {
          return (!!v || this.form.urgentType != 'その他') || '緊急連絡先種別が「その他」の場合必須'
        },
        notifyZipcode: (v) => {
          return !this.notifyZipcodeError || this.notifyZipcodeError
        }
        //   required: v => !!v || '必須項目です',
        //   tel: v => /^[+]?[\d-]*$/.test(v) || '番号が正しくありません',
        //   zip: v => /^[\d-]*$/.test(v) || '番号が正しくありません',
        //   kana: v => /^[ァ-ヶー　]*$/.test(v) || '全角カタカナで入力してください',
      },
      schema: {
        birthdate: {
          type: 'text',
          title: '生年月日'
        }
      },
      valid: false,
      countries: [],
      prefectures: [],
      urgentTypes: [
        '自宅',
        '携帯電話',
        '勤務先',
        'その他'
      ],
      // telRule: [this.rules.tel],
      // zipcodeRule: [this.rules.zipcode]
      isSelecting: false,
      zipcodeError: [],
      zipcodeInJapan: false,
      notifyZipcodeError: [],
      yearFormat: (year) => {
        if(year > 2018){
          return `令和${year - 2018}年`
        }else if(year > 1988){
          return `平成${year - 1988}年`
        }else if(year > 1925){
          return `昭和${year - 1925}年`
        }else if(year > 1911){
          return `大正${year - 1911}年`
        }else if(year > 1867){
          return `明治${year - 1867}年`
        }else{
          return year
        }
      }
    }
  },
  async created() {
    this.loading = true
    let p1 = this.fetchCountries()
    let p2 = this.fetchPrefectures()
    let p3 = this.fetchSaved()
    await Promise.all([p1, p2, p3])
    this.loading = false
  },
  computed: {
    ...mapGetters([
      'entryOpened',
      'isAfterRegistrationEnd',
      'isAfterWrittenExamTicketOpen',
      'isAfterWrittenExamResultOpen',
      'isAfterOralExamTicketOpen',
      'isAfterOralExamResultOpenAt'
    ]),
    submitted() {
      return this.$store.getters.submitted
    },
    requiredResubmit() {
      return this.$store.getters.requiredResubmit
    },
    hasSelectNationalityOther() {
      return this.form.nationality === 'その他'
    },
    minPictureDate() {
      return dayjs().subtract(6, 'month').format()
    },
    disableEditName() {
      if (this.requiredResubmit) return false;
      return (this.isAfterWrittenExamTicketOpen && !this.isAfterWrittenExamResultOpen)
        || (this.isAfterOralExamTicketOpen && !this.isAfterOralExamResultOpenAt)
    }
  },
  filters: {
    fileName(v) {
      return v && v.substring(v.lastIndexOf('/') + 1)
    }
  },
  watch: {},
  methods: {
    async checkZipcode(v, updateAddress) {
      //海外のzipcodeも可とする
      let ret = await this.findZipCode(v)
      if (!ret) {
        this.zipcodeInJapan = false
        return
      }
      this.zipcodeInJapan = true
      if (updateAddress) {
        this.form.prefecture = ret.prefecture
        this.form.address = ret.city + ret.street
      }
    },
    async checkNotifyZipcode(v) {
      this.notifyZipcodeError = [];
      let ret = await this.findZipCode(v)
      if (!ret) {
        this.notifyZipcodeError = '実在する日本国内の郵便番号ではありません'
      }
      this.form.notifyAddress = ret.prefecture + ret.city + ret.street
    },
    async checkJapanAddress(event, v) {
      if (!this.zipcodeInJapan) {
        await this.checkZipcode(this.form.zipcode)
      }
      if (!this.zipcodeInJapan) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()
        alert('現住所に日本国内に存在しない郵便番号が入力されています。正しく入力してください。')
        this.form.notifySameAddress = false
        return false
      }
    },
    async fetchPrefectures() {
      this.prefectures = prefectures.map(v => v.name)
      this.prefectures.unshift('')
    },
    async fetchCountries() {
      this.countries = countries.map(v => v.companyjp)
      this.countries.push('その他')
      this.countries.unshift('')
    },
    async fetchSaved() {
      let {data} = await axios.get('/api/entry/draft')
      console.log('fetchSaved', data)
      if (data) this.form = Object.assign(this.form, data)
    },
    clickUpload() {
      if (this.submitted && !this.requiredResubmit) return false
      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.uploadPhoto(e.target.files[0])
    },
    async uploadPhoto(file) {
      //size
      if (file.size > 10485760) {
        alert('ファイルサイズは10MB以下でアップロードしてください')
        return
      }
      this.isSelecting = true
      if (!file) {
        this.form.picture = null
        this.form.pictureUrl = null
        return
      }
      let params = new FormData()
      params.append('file', file)
      let {data} = await axios.post('/api/visitor/file/upload/photo', params)
      this.form.picture = data.id
      this.form.pictureUrl = data.url
      this.isSelecting = false
    },
    removeDocument(item) {
      this.form.documents = this.form.documents.filter(v => v.fileId != item.fileId)
    },
    async upload(file) {
      if (!file) return
      if (Array.isArray(file)) {
        this.loading = true
        try {
          for (let i in file) {
            await this.upload(file[i])
          }
        } catch (e) {
          console.error(e)
        }
        this.loading = false
        return
      }
      if (file.size > 20971520) {
        alert('ファイルサイズは20MB以下でアップロードしてください')
        return
      }
      let params = new FormData()
      params.append('file', file)
      let {data} = await axios.post('/api/visitor/file/upload', params)
      this.form.documents.push({
        fileId: data.id,
        key: file.name
      })
    },
    async save() {
      try {
        await axios.post('/api/entry/save', this.form)
        this.$emit('toast', '保存しました')
      } catch (e) {
        console.error(e)
        this.$emit('error', 'エラーが発生しました。しばらくしてから再度お試しください')
      }
    },
    async submit() {
      try {
        if (!this.$refs.form.validate()) {
          return
        }
        await axios.post('/api/entry/submit', this.form)
        this.$emit('toast', '確定しました')
        if (this.submitted) return
        await this.$router.push('/mypage')
      } catch (e) {
        console.error(e)
        this.$emit('error', 'エラーが発生しました。しばらくしてから再度お試しください')
      }
    }
  }
}
</script>
<style scoped>
h3 {
  font-size: 1rem;
  text-align: left;
  color: #363636;
  background-color: #F5F5F5;
  padding: 0.3rem;
}
</style>
<style>
.photo-message .v-messages {
  text-align: center;
}

.photo-message .v-input__slot {
  display: none;
}
</style>
