<template>
  <div>
    <Loading v-model="loading"></Loading>
    <v-sheet
      class="mb-9 pa-7"
      color="white" v-show="tokenVerified">
      <div class="Title">
        メールアドレスの確認
      </div>
      ご登録いただいたメールアドレスの確認が取れました。<br>
      メールアドレスの変更が完了しました

      <div class="ma-12">
        <v-btn
          color="primary" x-large
          @click.stop="$emit('openLogin')"
        >マイページへ
        </v-btn>
      </div>

    </v-sheet>
    <p v-if="tokenInvalid">
      メールアドレスの確認ができませんでした。
    </p>
    <Toast></Toast>
  </div>
</template>
<script>
import axios from 'axios'
import Rules from '../mixins/RulesMixin'
import Loading from '../components/Loading'

export default {
  components: {Loading},
  mixins: [Rules],
  data () {
    return {
      loading: false,
      valid: false,
      token: '',
      password: '',
      tokenVerified: false,
      tokenInvalid: false
    }
  },
  async created () {
    this.loading = true
    await this.confirmToken()
    this.loading = false
  },
  methods: {
    async confirmToken () {
      try {
        this.token = this.$route.query.token
        await axios.post('/api/account/change_email/confirm/' + this.token)
        this.tokenVerified = true
      } catch (e) {
        console.error(e)
        this.tokenInvalid = true
      }
    }
  }
}
</script>
