// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'formdata-polyfill'
import Vue from 'vue'
import VisitorApp from './event/EventApp'
import router from './event/router'
import store from './event/store'
import VueCookie from 'vue-cookies'
import vuetify from './plugins/vuetify'
import axios from 'axios'

require(process.env.VUE_APP_MOCK);

Vue.config.productionTip = false
Vue.use(VueCookie)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(VisitorApp)
}).$mount('#app')


axios.interceptors.response.use(undefined, async function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await store.dispatch('Logout');
      return router.push('/');
    }
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      await store.dispatch('Logout');
      return router.push('/');
    }
    if (error.response.status === 500) {
      alert("問題が発生しました。しばらくしてから再度お試しください");
      return;
    }
  }
  return Promise.reject(error);
})
