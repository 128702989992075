import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {getToken, signout} from '../../utils/VisitorAPIUtils'
import dayjs from 'dayjs'

Vue.use(Vuex)

const LOGIN_KEY = 'prev'
let loggedInPrev = localStorage.getItem(LOGIN_KEY)

export default new Vuex.Store({
  state: {
    loggedIn: !!loggedInPrev,
    me: null,
    event: {},
  },
  getters: {
    isAuthenticated (state) {
      return state.loggedIn
    },
    loggedIn (state) {
      return state.loggedIn
    },
    me (state) {
      return state.me || {}
    },
    event (state) {
      return state.event
    },
    entryOpened (state) {
      let date = state.event.timeline && state.event.timeline.registrationStartAt
      let registrationEndAt = state.event.timeline && state.event.timeline.registrationEndAt
      let notEnded = !registrationEndAt || dayjs().isBefore(registrationEndAt)
      return (!date || dayjs().isAfter(date)) && notEnded
    },
    loginOpened (state) {
      let registrationStartAt = state.event.timeline && state.event.timeline.registrationStartAt
      return (!registrationStartAt || dayjs().isAfter(registrationStartAt))
    },
    isAfterRegistrationEnd(state){
      let registrationEndAt = state.event.timeline && state.event.timeline.registrationEndAt
      return !!registrationEndAt && dayjs().isAfter(registrationEndAt)
    },
    isAfterWrittenExamTicketOpen(state){
      let writtenExamTicketOpenAt = state.event.timeline && state.event.timeline.writtenExamTicketOpenAt
      return !!writtenExamTicketOpenAt && dayjs().isAfter(writtenExamTicketOpenAt)
    },
    isAfterWrittenExamResultOpen(state){
      let writtenExamResultOpenAt = state.event.timeline && state.event.timeline.writtenExamResultOpenAt
      return !!writtenExamResultOpenAt && dayjs().isAfter(writtenExamResultOpenAt)
    },
    isAfterOralExamTicketOpen(state){
      let oralExamTicketOpenAt = state.event.timeline && state.event.timeline.oralExamTicketOpenAt
      return !!oralExamTicketOpenAt && dayjs().isAfter(oralExamTicketOpenAt)
    },
    isAfterOralExamResultOpenAt(state){
      let oralExamResultOpenAt = state.event.timeline && state.event.timeline.oralExamResultOpenAt
      return !!oralExamResultOpenAt && dayjs().isAfter(oralExamResultOpenAt)
    },
    eventInfo (state) {
      return state.event && state.event.eventInfo || {}
    },
    submitted (state) {
      return state.me && !!state.me.submittedAt
    },
    requiredResubmit (state) {
      return state.me && state.me.entryStatus == 'WAIT_FOR_RESUBMIT'
    },
    accepted (state) {
      return state.me && state.me.entryStatus == 'ACCEPTED'
    },
    isPre (state) {
      return location.hostname == 'pre.shiken.jnto.go.jp'
    },
    paid(state){
      return state.me && state.me.ticketOrder && state.me.ticketOrder.result == 'Success'
    },
    privacyPolicyUrl(state){
      return "https://www.jata-net.or.jp/about/jata-information/about06_04/page-2797/"
    }
  },
  mutations: {
    login (state, me) {
      state.loggedIn = true
      state.me = me
      localStorage.setItem(LOGIN_KEY, true)
    },
    logout (state) {
      state.loggedIn = false
      state.me = null
      localStorage.removeItem(LOGIN_KEY)
    },
    setEvent (state, event) {
      state.event = event
    },
  },
  actions: {
    async login ({commit, dispatch}, options) {
      try {
        let {data} = await getToken(options)
        await dispatch('getMe')
        return true
      } catch (err) {
        console.error(err)
        commit('logout')
        alert('メールアドレスまたはパスワードが間違っています。')
        return false
      }
    },
    async getMe ({commit, dispatch}) {
      try {
        let {data} = await axios.get('/api/visitor/me')
        commit('login', data)
      } catch (e) {
        commit('logout')
      }
    },
    async getEvent ({commit, dispatch}) {
      try {
        let {data} = await axios.get('/api/eventsite')
        commit('setEvent', data)
      } catch (e) {
        commit('logout')
        commit('setEvent', null)
      }
    },
    async logout ({commit}) {
      await signout()
      commit('logout')
    }
  },
  modules: {}
})
