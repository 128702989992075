<template>
  <form ref="paymentForm">
    <p>
      ※お支払い後の返金は受付できません。<br />
      ※受講申込みに関する特定商取引法に基づく表示はページの下部よりご確認ください。
    </p>

<!--    <v-btn @click="submit" color="primary" rounded class="white&#45;&#45;text buttonStyle" :disabled="disabled">-->
<!--      <slot></slot>-->
<!--    </v-btn>-->
    <v-btn @click="submit2" color="primary" rounded class="white--text buttonStyle" :disabled="disabled">
      <slot></slot>
    </v-btn>
  </form>
</template>
<script>
import dayjs from "dayjs";
import {Order, OrderDetail} from "@/modules/SBPOrder"
import axios from "axios";

const crypto = require('crypto');
const sbp_url = 'https://stbfep.sps-system.com/f01/FepBuyInfoReceive.do'

export default {
  props: {
    ticket: Object,
    disabled: Boolean
  },
  methods: {
    async submit2() {
      try {
        // const req = new FormData();
        // req.append("ticket_id", "this.ticket.ticket_id");
        let {data} = await axios.post("/api/entry/sbp/get_params", {
          ticketId: this.ticket.id
        })
        console.log(data)
        let order = data.order
        let details = order.detailList
        delete order.detailList
        console.log(order, details, data)
        let form = this.$refs.paymentForm
        form.action = data.request_url
        form.method = 'POST'
        form.acceptCharset = "shift_jis"
        form.addEventListener('formdata', (e) => {
          for (let [k, v] of Object.entries(order)) {
            e.formData.append(k, v)
          }
          for (const d of details) {
            console.log("details", d, details)
            for (let [k, v] of Object.entries(d)) {
              e.formData.append(k, v)
            }
          }
          e.formData.append("sps_hashcode", data.sps_hashcode)
        })
        form.submit()
      } catch (e) {
        console.error(e)
      }
    },
    submit() {
      let form = this.$refs.paymentForm
      form.action = sbp_url
      form.method = 'POST'
      form.acceptCharset = "shift_jis"
      form.addEventListener('formdata', (e) => {
        let order = this.getOrder();
        for (let [k, v] of Object.entries(order)) {
          e.formData.append(k, v)
        }
      })
      form.submit()
    },
    getOrder() {
      let data = {
        pay_method: "credit",
        merchant_id: "30132",
        service_id: "001",
        cust_code: "Merchant_TestUser_999999",
        sps_cust_no: "",
        sps_payment_no: "",
        order_id: this.randomString(),
        item_id: "T_0003",
        pay_item_id: "",
        // item_name: "item_name",
        item_name: "テスト商品",
        tax: "",
        amount: "1",
        pay_type: "0",
        auto_charge_type: "",
        service_type: "0",
        div_settele: "",
        last_charge_month: "",
        camp_type: "",
        tracking_id: "",
        terminal_type: "0",
        success_url: "http://localhost:8080/mypage",
        cancel_url: "http://localhost:8080/mypage",
        error_url: "http://localhost:8080/mypage",
        pagecon_url: "http://stbfep.sps-system.com/MerchantPayResultRecieveSuccess.jsp",
        // pagecon_url: "http://demo.allinone-series.com/sony_hook",
        free1: "",
        free2: "",
        free3: "",
        free_csv: "",
        // request_date: "20220521150000",
        request_date: dayjs().format("YYYYMMDDHHmmss"),
        limit_second: "",
        // hashkey: "c48e0e2c7d04f0954594f14c7801bd430ca6263e"
      }
      let order = new Order();
      Object.assign(order, data)

      let orderDetailData = {
        dtl_rowno: "1",
        dtl_item_id: "dtl_item_id",
        dtl_item_name: "dtl_item_name",
        dtl_item_count: "1",
        dtl_tax: "1",
        dtl_amount: "1",
        dtl_free1: "",
        dtl_free2: "",
        dtl_free3: "",
      }
      let orderDetail = new OrderDetail();
      Object.assign(orderDetail, orderDetailData)
      order.orderDetail.push(orderDetail)

      const shasum = crypto.createHash('sha1');
      shasum.update(order.toString(), 'utf8')

      let sps_hashcode = shasum.digest('hex');
      data.sps_hashcode = sps_hashcode
      // await axios.post(sbp_url, data)
      Object.assign(data, orderDetailData)
      console.log(order, orderDetail, data)
      return data
    },
    randomString() {
      // return "2b4795cd-6dab-482c-a6c8-77ae0be8c37b"
      var S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
      var N = 38
      return Array.from(Array(N)).map(() => S[Math.floor(Math.random() * S.length)]).join('')
    }
  }
}
</script>
<style scoped>
.buttonStyle {
  width: 50%;
}
</style>
