import axios from 'axios'

const API_BASE_URL = '/api/' //process.env.API_BASE_URL
const GET_TOKEN_URL = '/auth/login' //process.env.GET_TOKEN_URL

export const request = (options) => {
    let headers = {
        'Content-Type': 'application/json'
    }

    if (options.headers) {
        headers = {...headers, ...options.headers}
        delete options["headers"]
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options, {withCredentials: true})

    return axios(options)
            .then(res => {
                if (res.config.url.includes("/login")) throw Error("authenticated")
                return res
            })
}

export const getToken = (options) => {

    let data = new FormData()
    data.set('username', options.username)
    data.set('password', options.password)
    let populated_option = {
        url: GET_TOKEN_URL,
        headers : {
            'accountType': options.accountType,
            eventId: options.eventId
        },
        config: {
            headers: {'Content-Type': 'mutipart/form-data'}
        },
        method: 'post',
        data: data,
        withCredentials: true

    }
    return axios(populated_option)
}

export const recordMyPageAccess = options => {
  return request({
    url: API_BASE_URL + 'visitor/mypage/access/' + options.publishId,
    method: 'put',
    ...options
  })
}

export const getMe = options => {
    return request({
        url: API_BASE_URL + 'visitor/me/',
        method: 'get',
        ...options
    })
}

export const applyEvent = options => {
    return request({
        url: API_BASE_URL + 'visitor/apply_event/',
        method: 'post',
        ...options
    })
}

export const getEventId = options => {
  return request({
    url: API_BASE_URL + 'eventsite/' + options.publishId + '/id',
    method: 'get',
    ...options
  })
}

export const getEvent = options => {
    return request({
        url: API_BASE_URL + 'eventsite/' + options.publishId,
        method: 'get',
        ...options
    })
}

export const getTickets = options => {
  return request({
    url: API_BASE_URL + 'eventsite/tickets/' + options.eventId,
    method: 'get',
    ...options
  })
}

export const verifyPrivateSiteAccess = options => {
    console.log(options)
    return request({
        url: API_BASE_URL + 'eventsite/',
        method: 'post',
        ...options
    })
}

export const getEventSiteUrl = options => {
  return request({
    url: API_BASE_URL + 'eventsite/getUrl/' + options.eventId,
    method: 'get',
    ...options
  })
}

export const signout = options => {
    return request({
        url: API_BASE_URL + 'logout',
        method: 'get',
        ...options
    })
}

export const cancelEvent = options => {
    return request({
        url: API_BASE_URL + 'visitor/me/' + options.eventId,
        method: 'delete',
        ...options
    })
}

export const getQrCode = options => {
    return API_BASE_URL + "visitor/qr/" + options.publicKey
}

export const updateVisitor = options => {
    return request({
        url: API_BASE_URL + 'visitor/update',
        method: 'post',
        ...options
    })
}

export const getSharedFiles = options => {
    return request({
        url: API_BASE_URL + 'file/' + options.eventId + '/shared',
        method: 'get',
        ...options
    })
}

export const getSharedVideos = options => {
  return request({
    url: API_BASE_URL + 'sharedvideo/mypage/' + options.eventId + '/allopen',
    method: 'get',
    ...options
  })
}

export const getTicketUrl = options => {
    return API_BASE_URL + 'visitor/' + options.eventId + '/ticket'
}

export const getTicketRemainCounts = options => {
    return request({
        url: API_BASE_URL + 'eventsite/' + options.eventId + '/ticket_remain_counts',
        method: 'get',
        ...options
    })
}

export const isPaymentEnable = options => {
    return request({
        url: API_BASE_URL + 'payment/eventsite/' + options.eventId + '/is_enable',
        method: 'get',
        ...options
    })
}

export const getSqEnvironment = options => {
    return request({
      url: API_BASE_URL + 'square/getEnvironment',
      method: 'get',
      ...options
    })
}

export const getSqPreOrderInfo = options => {
    return request( {
        url: API_BASE_URL + 'square/payment/pre_order_info/' + options.uuid,
        method: 'get',
        ...options
    })
}

export const completePreOrder = options => {
    return request({
        url: API_BASE_URL + 'visitor/pre_order/' + options.uuid + '/complete',
        method: 'post',
        ...options
    })
}

export const getReceiptUrl = options => {
  return API_BASE_URL + 'visitor/' + options.eventId + '/receipt'
}

export const getBundleSite = options => {
  return request({
    url: API_BASE_URL + 'publishbundlesite/' + options.publishId,
    method: 'get',
    ...options
  })
}

export const verifyPrivateBundleSiteAccess = options => {
  console.log(options)
  return request({
    url: API_BASE_URL + 'publishbundlesite/',
    method: 'post',
    ...options
  })
}

export const readyLandingVcube = options => {
  return request({
    url: API_BASE_URL + 'visitor/' + options.eventId + '/readyLandingVcube',
    method: 'get',
    ...options
  })
}

export const doLandingVcube = options => {
  return request({
    url: API_BASE_URL + 'visitor/' + options.eventId + '/doLandingVcube',
    method: 'get',
    ...options
  })
}

export const getLandingUrlZoom = options => {
  return request({
    url: API_BASE_URL + 'visitor/' + options.eventId + '/getLandingUrlZoom',
    method: 'get',
    ...options
  })
}
